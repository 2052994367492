<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='createAdditionalCost'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Cost</h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="addCost" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Additional Cost Details</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="title">Title</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.title.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Title"
                                    id="title"
                                    @blur="$v.formData.title.$touch()"
                                    v-model.trim="formData.title"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.title.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.required"
                            >Title field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.minLength"
                            >At least 2 character title.</p>
                          </div>
                        </div>
                        <div class="row mt-2 d-none">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Quantity</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.quantity.$error }">
                            <input
                                    type="number"
                                    class="form-control border-radius-0"
                                    placeholder="Quantity"
                                    @blur="$v.formData.quantity.$touch()"
                                    v-model.trim.number="formData.quantity"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.quantity.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.quantity.required"
                            >Quantity field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.quantity.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Price</label>
                          </div>
                          <div class="col-md-6">
                            <input
                                    type="number"
                                    step="any"
                                    class="form-control border-radius-0"
                                    placeholder="Price"
                                    v-model.trim.number="formData.price"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  import { mapGetters } from "vuex";
  import axios from "axios";
  export default {
    computed: {
      ...mapGetters([
        "apiRoot",
        "token",
        "processing",
        "fileLocation",
        "eventMessage",
        "dataLists",
        "modalId",
        "modalState",
      ]),
    },
    data() {
      return {
        formData: {
          title: "",
          quantity: "1",
          price: "",
        },
      };
    },
    validations: {
      formData: {
        title: { required, minLength: minLength(2), maxLength: maxLength(255) },
        quantity: { required, numeric },
        price: { required, numeric },
      },
    },
    methods: {
      addCost() {
        if (!this.$v.formData.$invalid) {
          this.$store.commit("setApiUrl", "api/cost/additional");
          this.$store.commit("addData", this.formData);
        } else {
          this.$store.commit("setEventMessage", `Please fill required fields.`);
        }
      },
    },
    watch: {
      eventMessage(value) {
        if (value && value.indexOf("success") >= 0) {
          this.formData.title = "";
          this.formData.quantity = "";
          this.formData.price = "";
        }
      },
    },
  };
</script>